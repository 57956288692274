import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

import { breakpoints, colors, fonts } from '../../lib/constants';
import Blob from '../Blob';

export const FirstBlob = styled.div({
  position: 'absolute',
  left: '0',
  bottom: '0',
  color: colors.baseBlue,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    bottom: '-2%',
  },
});

export const SecondBlob = styled.div({
  position: 'absolute',
  right: '0',
  top: '0',
  color: colors.baseBlue,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    top: '-2%',
  },
});

export const Container = styled.div({
  position: 'relative',
  margin: '0 auto',
  textAlign: 'center',
  fontSize: 16,
  fontFamily: fonts.bold,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    margin: '50px auto 0 auto',
    padding: '0 20px',
  },
});

function Blobs({ image }) {
  return (
    <Container>
      <Img fluid={image} />
      <FirstBlob>
        <Blob responsive variant="funky" fill="#fff" size={220} contentFontSize={16}>
          Över 350 genomförda föreläsningar och kurser
        </Blob>
      </FirstBlob>
      <SecondBlob>
        <Blob responsive contentFontSize={16}>
          2016 startade vi Sveriges första beteendebyrå
        </Blob>
      </SecondBlob>
    </Container>
  );
}
export default Blobs;
