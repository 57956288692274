import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import Button from '../reusables/Button';
import ContentWrapper from '../reusables/ContentWrapper';

import { breakpoints, colors, fonts } from '../../lib/constants';
import {
  SecondaryHeading,
  Paragraph1,
  Paragraph3,
} from '../reusables/textElements';
import Blob from '../Blob';
import CenterChild from '../reusables/CenterChild';

const Container = styled.div({
  color: colors.baseBlue,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'block',
  },
});

const Courses = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 40,
  marginBottom: 50,
  marginTop: 50,
  color: colors.baseBlue,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'block',
  },
});

const CoursesIntro = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr',
  width: '60%',

  [`@media (max-width: 800px)`]: {
    width: '100%',
  },
});

const StyledLink = styled(Link)({
  fontSize: 28,
  lineHeight: 1.1,
  color: '#fff',
  // fontFamily: fonts.bold,
});

const Left = styled.div({
  maxWidth: 600,
  width: '100%',
  marginRight: 140,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    maxWidth: 'none',
  },
});

const Item = styled.div({
  width: '100%',

  [`@media (max-width: 800px)`]: {
    marginBottom: '70px',
  },
});

const ItemOne = styled.div({
  display: 'flex',
  borderRightColor: '#030072',
  borderRightWidth: 1,
  borderRightStyle: 'solid',
  [`@media (max-width: 800px)`]: {
    border: 'none',
    marginBottom: '40px',
  },
});

const ItemTwo = styled.div({
  display: 'flex',
});

const QuoteText = styled.div({
  width: '80%',
  marginRight: 20,
});

const StyledImg = styled(Img)({
  marginBottom: 15,
});
const Title = styled.div({
  marginTop: 20,
  fontSize: 30,
  fontFamily: fonts.bold,
});

const ImageText = styled(Paragraph3)({
  marginBottom: 70,
});

const CourseButton = styled.div({
  marginTop: '1rem',
  textAlign: 'center',
});

const Quote = styled.div({
  fontSize: 28,
});

const SubTitle = styled.div({ fontSize: 20, fontFamily: fonts.bold });
const QuoteSign = styled.div({
  fontSize: 200,
  fontFamily: fonts.bold,
  marginRight: 20,
  marginTop: '-33px',
  alignSelf: 'flex-start',
  lineHeight: 0,
  [`@media (max-width: 500px)`]: {
    fontSize: 100,
    marginTop: '-10px',
  },
});

function OurCourses({ image2, image3, image5, image7, allContentfulCourse }) {
  return (
    <Container>
      <ContentWrapper>
        <CoursesIntro>
          <SecondaryHeading css={{ marginBottom: 30, marginTop: 30 }}>
            Våra kurser
          </SecondaryHeading>
          <Paragraph1 css={{ marginBottom: 30 }}>
            Vi anordnar kurser, workshops och webbinarium med olika tematik och
            inom olika fokusområden, alltid med utgångspunkt i beteendedesign,
            nudging och tjänstedesign. Kontakta oss så skräddarsyr vi något som
            passar just er organisation!
          </Paragraph1>
        </CoursesIntro>
        <Courses>
          {allContentfulCourse.edges.map((item) => {
            const course = item.node;
            return (
              <Item key={course.id}>
                <StyledImg sizes={course.image.sizes} />
                <Title>{course.title}</Title>
                <Paragraph1
                  css={{ marginTop: 30 }}
                  dangerouslySetInnerHTML={{
                    __html: course.description.childMarkdownRemark.html,
                  }}
                />
                <Paragraph1 css={{ marginTop: 30 }}>
                  <SubTitle>Nästa kurstillfälle</SubTitle>
                  {course.date}
                </Paragraph1>
                <CourseButton>
                  <Button
                    variant="filled"
                    css={{
                      margin: 10,
                      backgroundColor: 'rgb(255, 143, 223)',
                      color: '#030072',
                    }}
                    as={Link}
                    target="_blank"
                    to={course.registrationUrl}
                  >
                    Anmäl dig här
                  </Button>
                </CourseButton>
              </Item>
            );
          })}
        </Courses>

        <Courses css={{ marginTop: 100 }}>
          <ItemOne>
            <QuoteSign>,,</QuoteSign>
            <QuoteText>
              <Quote>
                Mycket inspirerande kurs. Den var uppiggande och väckte
                engagemang och jag tycker att jag fick med mig bra tankar och
                verktyg till mitt arbete. Tack!
              </Quote>
              <Paragraph1 css={{ marginTop: 30 }}>
                Kursdeltagare Nudging för hållbara städer 19/5 2020
              </Paragraph1>
            </QuoteText>
          </ItemOne>

          <ItemTwo>
            <QuoteSign>,,</QuoteSign>
            <QuoteText>
              <Quote>
                Förbered dig på att hjärnan expanderar för här blandas praktiska
                tips och övningar med best practice.
              </Quote>
              <Paragraph1 css={{ marginTop: 30 }}>
                Kursdeltagare Nudging för kommunikatörer 9/6 2021
              </Paragraph1>
            </QuoteText>
          </ItemTwo>
        </Courses>
      </ContentWrapper>

      <ContentWrapper>
        <CoursesIntro>
          <SecondaryHeading css={{ marginBottom: 30 }}>
            Boka oss som föreläsare
          </SecondaryHeading>
          <Paragraph1 css={{ marginBottom: 30 }}>
            Ge er verksamhet en skjuts med inspirerande kompetenshöjning! Vi
            föreläser kring hållbar beteendeförändring inom en rad områden och
            branscher som mobilitet, fastighet & bygg, matproduktion och vård &
            hälsa. Vi har tidigare deltagit på scener som Klimatforum, Women in
            tech, Mötesplats Agenda 2030 och Samhällsbyggardagarna och medverkat
            som experter i medier som DN, SVT och Sveriges Radio.
          </Paragraph1>
        </CoursesIntro>
        <Item>
          <StyledImg fluid={image5.childImageSharp.fluid} />
          <CourseButton>
            <Button
              variant="filled"
              css={{
                margin: 10,
                backgroundColor: 'rgb(255, 143, 223)',
                color: '#030072',
              }}
              as={Link}
              to="/contact"
            >
              Kontakta oss
            </Button>
          </CourseButton>
        </Item>
      </ContentWrapper>

      <ContentWrapper>
        <CoursesIntro>
          <SecondaryHeading css={{ marginBottom: 30 }}>
            Nudging i praktiken
          </SecondaryHeading>
          <Paragraph1 css={{ marginBottom: 30 }}>
            Vi tror på hållbar utveckling som gynnar både individ, näringsliv
            och klimat. Vår handbok i Nudging hjälper nyfikna praktiker att
            kombinera metoder från psykologi, tjänstedesign och beteendeekonomi
            för en systematisk och mer resultatorienterad beteendeförändring
            inom och utanför organisationen.
          </Paragraph1>
        </CoursesIntro>
        <Item>
          <StyledImg fluid={image7.childImageSharp.fluid} />
          <CourseButton>
            <Button
              variant="filled"
              css={{
                margin: 10,
                backgroundColor: 'rgb(255, 143, 223)',
                color: '#030072',
              }}
              as={Link}
              to="/contact"
            >
              Kontakta oss
            </Button>
          </CourseButton>
        </Item>
      </ContentWrapper>
    </Container>
  );
}
export default OurCourses;
