import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../lib/constants';
import { breakpoints } from '../lib/constants';
import AppChrome from '../components/AppChrome';
import Blobs from '../components/about/Blobs';
import ContentWrapper from '../components/reusables/ContentWrapper';
import ThirdSection from '../components/about/ThirdSection';
import TopNav from '../components/TopNav';
import TopRow from '../components/about/TopRow';
import WhatWeDoAbout from '../components/about/WhatWeDoAbout';
import OurCourses from '../components/about/OurCourses';
import blobBackground from '../assets/bakgrundsform.svg';

const Container = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 500px',
  columnGap: 20,
  marginTop: 50,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'block',
  },
});

const Left = styled.div({
  width: '100%',
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    width: '100%',
  },
});

const Right = styled.div({
  margin: 'auto',
  width: '100%',
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    minWidth: 320,
    maxWidth: 450,
  },
});

const ContentWrapperCourses = styled.div({
  marginBottom: '-12rem',
  paddingBottom: '12rem',
  backgroundImage: `url(${blobBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center right',
  backgroundColor: '#fff',
});

const mainDesc = `
  Vi betraktar världen genom en beteendelins med syftet att göra det lätt att göra
  rätt. Vi undersöker beteenden, innoverar lösningar och utvärderar effekter för
  att skapa positiv förändring för både person, planet och plånbok.
  `;

function About({ data }) {
  return (
    <AppChrome
      title="Vilka är vi?"
      backgroundColor={colors.baseBlue}
      css={{ color: '#fff' }}
      og={{
        title: 'Förändrar vi beteenden förändrar vi världen',
        description: mainDesc,
        image: data.image1.childImageSharp.fluid.src,
      }}
    >
      <TopNav white />
      <ContentWrapper css={{ background: colors.baseBlue }}>
        <Container>
          <Left>
            <TopRow />
          </Left>
          <Right>
            <Blobs image={data.image1.childImageSharp.fluid} />
          </Right>
        </Container>
      </ContentWrapper>
      <ContentWrapper css={{ background: colors.baseBlue }}>
        <ThirdSection />
        <WhatWeDoAbout />
      </ContentWrapper>
      <ContentWrapperCourses>
        <OurCourses {...data} />
      </ContentWrapperCourses>
    </AppChrome>
  );
}

export default About;

export const fragmentedImage = graphql`
  fragment fragmentedImage on File {
    childImageSharp {
      fluid(maxWidth: 1600) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
export const pageQuery = graphql`
  query {
    image1: file(relativePath: { eq: "whatwedo-top-image.png" }) {
      ...fragmentedImage
    }
    image2: file(relativePath: { eq: "about2.jpg" }) {
      ...fragmentedImage
    }
    image3: file(relativePath: { eq: "about3.jpg" }) {
      ...fragmentedImage
    }
    image5: file(relativePath: { eq: "linda_lindstrom.jpg" }) {
      ...fragmentedImage
    }
    image7: file(relativePath: { eq: "about-book.jpg" }) {
      ...fragmentedImage
    }
    allContentfulCourse(sort: { fields: [date], order: ASC }, filter: { active: { eq: true } } ) {
      edges {
        node {
          id
          title
          date(formatString: "DD MMMM YYYY", locale: "sv")
          registrationUrl
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            sizes(maxWidth: 700, maxHeight: 392, resizingBehavior: FILL) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
  }
`;
