import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../lib/constants';
import ContentWrapper from '../reusables/ContentWrapper';
import { PrimaryHeading } from '../reusables/textElements';

const ContentBlock = styled.div({
  width: '100%',
});

const Text = styled.p({
  fontSize: 18,
  lineHeight: '24px',
  margin: 0,
  marginBottom: 20,
});

function TopRow() {
  return (
    <ContentBlock>
      <PrimaryHeading>
        FÖRÄNDRAR VI BETEENDEN FÖRÄNDRAR VI VÄRLDEN
      </PrimaryHeading>
      <Text css={{ marginTop: 20 }}>
        Vi betraktar världen genom en beteendelins med syftet att göra det lätt
        att göra rätt. Vi undersöker beteenden, innoverar lösningar och
        utvärderar effekter för att skapa positiv förändring för både person,
        planet och plånbok.
      </Text>
    </ContentBlock>
  );
}

export default TopRow;
