import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { breakpoints, colors } from '../../lib/constants';
import {
  SecondaryHeading,
  Paragraph1,
  Paragraph3,
} from '../reusables/textElements';
import Blob from '../Blob';
import CenterChild from '../reusables/CenterChild';

const Container = styled.div({
  marginTop: 20,
  display: 'flex',
  justifyContent: 'space-between',
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'block',
  },
});
const StyledLink = styled(Link)({
  fontSize: 28,
  lineHeight: 1.1,
  color: '#fff',
  // fontFamily: fonts.bold,
});
const Left = styled.div({
  maxWidth: 600,
  width: '100%',
  marginRight: 140,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    maxWidth: 'none',
  },
});
const Right = styled.div({
  width: '100%',
});

const ImageText = styled(Paragraph3)({
  marginBottom: 70,
});

function ThirdSection() {
  return (
    <Container>
      <Left>
        <SecondaryHeading css={{ marginBottom: 30 }}>
          VAD VI GÖR
        </SecondaryHeading>
        <Paragraph1 css={{ marginBottom: 70 }}>
          Beteendelabbet är Sveriges första beteendebyrå! Vi arbetar
          tvärdisciplinärt och kombinerar psykologi, design och
          ekonomi med användarens beteenden i fokus.
        </Paragraph1>
      </Left>
    </Container>
  );
}
export default ThirdSection;
